import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup,FormBuilder } from '@angular/forms';
import { CardDetailService } from '../card-detail.service';

@Component({
  selector: 'app-sociallink',
  templateUrl: './sociallink.component.html',
  styleUrls: ['./sociallink.component.css']
})
export class SociallinkComponent implements OnInit {
  form: FormGroup;
  formGroupUpdate: FormGroup;
  socialForm: FormGroup;
  email: "bhallaitsolutions@gmail.com"
  userId: any
  socialDetails: any
  constructor(private fb: FormBuilder,private cardDetailService: CardDetailService) {
    this.socialForm = new FormGroup({
      // skype: this.socialForm.value.skype,
      // whatsapp: this.socialForm.value.whatsapp,
      // facebook: this.socialForm.value.facebook,
      // twitter: this.socialForm.value.twitter,
      // instagram: this.socialForm.value.instagram,
      // snapchat: this.socialForm.value.snapchat,
      // googlePay: this.socialForm.value.googlePay,
      // linkedin: this.socialForm.value.linkedin,
      // pinterest: this.socialForm.value.pinterest,
      // meetup: this.socialForm.value.meetup,
      // telegram: this.socialForm.value.telegram,
      // youtube: this.socialForm.value.youtube,
      // blog: this.socialForm.value.blog
      skype: new FormControl(""),
      whatsapp: new FormControl(""),
      facebook: new FormControl(""),
      twitter: new FormControl(""),
      instagram: new FormControl(""),
      snapchat: new FormControl(""),
      googlePay: new FormControl(""),
      linkedin: new FormControl(""),
      pinterest: new FormControl(""),
      googlemeet: new FormControl(""),
      telegram: new FormControl(""),
      youtube: new FormControl(""),
      blog: new FormControl("")
    })
  }

  ngOnInit() {
    this.userId = localStorage.getItem("cardAdminToken")
    this.getSocialDetailById(this.userId)
  }

  update() {
    let temp = {
      ...this.socialForm.value,
      'clid': this.socialDetails['clid'],
      'cid': this.socialDetails['cid']
    }
    this.cardDetailService.updatesocialDetails(temp).subscribe(res => {
      console.log(res["Message"])
      if (res["Message"] === "Updated Successfully") {
        alert("Updated Successfully")
      }
      // this.basicInfoEdit = res
    })
    console.log("socialForm Value", this.socialForm.value, temp)
  }

  getSocialDetailById(userId) {
    debugger;
    this.cardDetailService.getSocialDetailById(userId).subscribe(res => {
      this.socialDetails = res
      this.socialForm = this.fb.group({
      skype: [res['cskype']],
      whatsapp: [res['cwhatsuapp']],
      facebook: [res['cfacebook']],
      twitter: [res['ctwitter']],
      instagram:[res['cinstagram']],
      snapchat: [res['csnapchat']],
      linkedin: [res['clinkedin']],
      googlePay:'',
      pinterest:[res['cpinterest']],
      googlemeet: [res['cgooglemeet']],
      telegram: [res['ctelegram']],
      youtube: [res['cyoutube']],
      id:[res['cid']],
      lid:[res['clid']],
      blog: '',
      })       
      console.log(this.socialForm,this.socialDetails)
    })
  }

}
