import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-editcard',
  templateUrl: './editcard.component.html',
  styleUrls: ['./editcard.component.css']
})
export class EditcardComponent implements OnInit {
  selecteditem: any = "Basic Info";

  constructor() { }

  ngOnInit() {
  }

  selectType(event) {
    console.log(event)
    this.selecteditem = event
  }
}
