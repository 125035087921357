import { Component, OnInit } from '@angular/core';
import { CardDetailService } from '../card-detail.service';
// import * as ClassicEditor from '@ckeditor/ckeditor5-angular';
import { QuillConfiguration } from "./quill-configuration";

class ImageSnippet {
  constructor(public src: string, public file: File) {
  }
}

@Component({
  selector: 'app-companydetail',
  templateUrl: './companydetail.component.html',
  styleUrls: ['./companydetail.component.css']
})

export class CompanydetailComponent implements OnInit {
  quillConfiguration = QuillConfiguration;
  companyDetail: any;
  txtOrganisation: any;
  userId:any;
  txtgstnumber:any;
  data;
  constructor(private cardService: CardDetailService) { }
  txtCompanyProfile:any;
  
  ngOnInit() {
    this.userId=localStorage.getItem("cardAdminToken")
    this.getDetails(this.userId)
  }

  selectInput(event) {
    console.log(event, "hhjhjh")
  }
  getDetails(userId) {
    debugger;
    this.cardService.companyDetail(userId).subscribe(res => {
      console.log("company details",res) 
      this.companyDetail = res
    })
  }
  update() {
    debugger; 
    let data =
    {
      "clid": this.companyDetail["clid"],
      "id": this.companyDetail["cid"],
      "companyname": this.companyDetail.ccname,//this.txtOrganisation.value,
      "businessnature": this.companyDetail.cnaturebusiness,
      "description": this.companyDetail.Message,
      "logo": this.data.src,
      "gstnum": this.companyDetail.cgstnum,
    }
    console.log(this.companyDetail)
    this.cardService.updateCompanyDetails(data).subscribe(res => {
      console.log(res["Message"])
      if (res["Message"] === "Updated Successfully") {
        alert("Updated Successfully")
      }
      // this.basicInfoEdit = res
    })
  }



  selectImage(event) {
    debugger
    if (event.target.files.length > 0) {
        console.log(event.target.files)
        const file: File = event.target.files[0];
        const reader = new FileReader();
        // tslint:disable-next-line:no-shadowed-variable
        reader.addEventListener('load', (event: any) => {
            this.data = new ImageSnippet(event.target.result, file);
            this.companyDetail.cimglogo = this.data.src
            console.log(this.data)
        });
        reader.readAsDataURL(file);
    }
}


}
