import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  selecttype: any = 'My Card';

  constructor(private router:Router) { }

  ngOnInit() {
    console.log("UserId",localStorage.getItem("cardAdminToken"))
  }
  logout(){
    localStorage.removeItem("cardAdminToken")
    this.router.navigate(['/'])
  }
  selectType(type){
    console.log(type)
    this.selecttype = type
  }
  selectionevent(event){
    console.log(event)
    this.selectType(event)
  }

  
}
