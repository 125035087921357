import { Component, OnInit } from '@angular/core';
import { CardDetailService } from '../card-detail.service';

@Component({
  selector: 'app-carddetail',
  templateUrl: './carddetail.component.html',
  styleUrls: ['./carddetail.component.css']
})
export class CarddetailComponent implements OnInit {

  editCard: boolean = false
  isEdit: boolean  = false;
  cardDetail: any;
  userId:any;
  constructor(private cardDetailService: CardDetailService) { }

  ngOnInit() {
    this.userId=localStorage.getItem("cardAdminToken")
    this.getCardDetailOfCard(this.userId)

  }

  editable(){
    this.isEdit = true
     
  }

  getCardDetailOfCard(userId){
    console.log("cardDetails",userId)
    this.cardDetailService.getCardDetail(userId).subscribe(res=>{
      console.log("CardDetail",res)
      this.cardDetail = res
    })

   
  }

}
