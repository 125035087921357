import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CardDetailService {

  constructor(private http:HttpClient) { }

  getCardDetail(id){
    return this.http.get(`${environment.API_URL}/GetCardDetails?id=${id}`)
  }


  basicCardDetail(id){
    return this.http.get(`${environment.API_URL}/GetBasicInfo?id=${id}`)
  }

  companyDetail(id){
    return this.http.get(`${environment.API_URL}/GetCompanyDetails?id=${id}`)
  }

  getProduct(id){
    return this.http.get(`${environment.API_URL}/GetProduct?id=${id}`)
  }
  updateBasicInfo(data) {
    return this.http.post(`${environment.API_URL}/PostBasicInfo`,data);
  }
  updateCompanyDetails(data) {
    return this.http.post(`${environment.API_URL}/PostCompanyDetail`,data);
  }
  updateProductDetails(data) {
    return this.http.post(`${environment.API_URL}/PostProductDetails`,data);
  }
  updatesocialDetails(data) {
    console.log(data ,"on service social")
    return this.http.post(`${environment.API_URL}/PostSocialLink`,data);
  }
  getSocialDetailById(id){
    return this.http.get(`${environment.API_URL}/GetSocialLink?id=${id}`)
  }
  PaymentDetail(id){
    return this.http.get(`${environment.API_URL}/GetPaymentDetails?id=${id}`)
  }

  updatePaymentDetail(data) {
    console.log(data ,"on service social")
    return this.http.post(`${environment.API_URL}/PostPaymentDetails`,data);
  }
}
