import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup,Validators } from '@angular/forms';
import { CardDetailService } from '../card-detail.service';

@Component({
  selector: 'app-basicinfo',
  templateUrl: './basicinfo.component.html',
  styleUrls: ['./basicinfo.component.css']
})
export class BasicinfoComponent implements OnInit {

  form: FormGroup;
  formGroupUpdate: FormGroup;
  basicInfoEdit: Object;
  userId:any;
  constructor(private fb: FormBuilder, private cardDetailService: CardDetailService) {
   
    this.formGroupUpdate = fb.group({
      cmobile: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
      cmobile1: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
      cmobile2: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
      cmobile3: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
      cmobile4: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
      cusername:  ['', [Validators.required]],
      cname:  ['', [Validators.required]],
      cdesignation:  ['', [Validators.required]],
      cemail:  ['', [Validators.required]],
      cemail1 :  ['', [Validators.required]],
      cwebsite:  ['', [Validators.required]],
      cwebsite1:  ['', [Validators.required]],
      caddress:  ['', [Validators.required]],
      caddress1:  ['', [Validators.required]],
      caddress2:  ['', [Validators.required]],
      cadddress3:  ['', [Validators.required]],

    })
    // this.formGroupUpdate = new FormGroup({
    //   cusername: new FormControl(''),
    //   cname: new FormControl(''),
    //   cdesignation: new FormControl(''),
    //   cemail: new FormControl(''),
    //   cemail1 : new FormControl(''),
    //   cmobile: new FormControl(''),
    //   cmobile1: new FormControl(''),
    //   cmobile2: new FormControl(''),
    //   cmobile3: new FormControl(''),
    //   cmobile4: new FormControl(''),
    //   cwebsite: new FormControl(''),
    //   cwebsite1: new FormControl(''),
    //   caddress: new FormControl(''),
    //   caddress1: new FormControl(''),
    //   caddress2: new FormControl(''),
    //   caddress3: new FormControl(''),
    //   // additionalPhone: fb.array([]),
    //   // additionalUrl: fb.array([]),
    //   // additionalAddresss: fb.array([])
    // })
    // this.form = fb.group({
    //   phone: fb.array([]),
    //   // phone : fb.array([])
    // });
   
  }


  ngOnInit() {
    this.userId=localStorage.getItem("cardAdminToken")
    this.basicInfo(this.userId)
  }

  // addPhone() {
  //   const add = this.formGroupUpdate.get('additionalPhone') as FormArray;
  //   add.push(this.fb.group({
  //     phone: []
  //   }))
  // }
  // deletePhoneGroup(index: number) {
  //   const add = this.formGroupUpdate.get('additionalPhone') as FormArray;
  //   add.removeAt(index)
  // }

  // additionalUrl(){
  //   const add = this.formGroupUpdate.get('additionalUrl') as FormArray;
  //   add.push(this.fb.group({
  //     website: []
  //   }))
  // }

  // deleteUrlGroup(index: number){
  //   const add = this.formGroupUpdate.get('additionalUrl') as FormArray;
  //   add.removeAt(index)
  // }

  // addAddress() {
  //   const add = this.formGroupUpdate.get('additionalAddresss') as FormArray;
  //   add.push(this.fb.group({
  //     adderss: []
  //   }))
  // }
  // deleteAddressGroup(index: number) {
  //   const add = this.formGroupUpdate.get('additionalAddresss') as FormArray;
  //   add.removeAt(index)
  // }

  

  update() {
    debugger
    // let data=
    // {
    //   "clid": this.basicInfoEdit["clid"],
    //   "id": this.basicInfoEdit["cid"],
    //   "cusername": this.formGroupUpdate.value.cusername,
    //   "cname": this.formGroupUpdate.value.cname,
    //   "cdesignation": this.formGroupUpdate.value.cdesignation,
    //   "cmobile": this.formGroupUpdate.value.cmobile,
    //   "cmobile1": this.formGroupUpdate.value.cmobile1,
    //   "cmobile2": this.formGroupUpdate.value.cmobile2,
    //   "cmobile3": this.formGroupUpdate.value.cmobile3,
    //   "cmobile4": this.formGroupUpdate.value.cmobile4,
    //   "cwebsite": this.formGroupUpdate.value.cwebsite,
    //   "cwebsite1":this.formGroupUpdate.value.cwebsite1,
    //   "caddress": this.formGroupUpdate.value.caddress,
    //   "caddress1":this.formGroupUpdate.value.caddress1,
    //   "caddress2": this.formGroupUpdate.value.caddress2,
    //   "caddress3":this.formGroupUpdate.value.caddress3,
    //   "cemail": this.formGroupUpdate.value.cemail,
    //   "cemail1":this.formGroupUpdate.value.cemail1,
    // }
let temp = {
  ...this.formGroupUpdate.value,
  'clid': this.basicInfoEdit['clid'],
  'id': this.basicInfoEdit['cid']
}
    this.cardDetailService.updateBasicInfo(temp).subscribe(res=>{
      console.log(res["Message"])
      if(res["Message"]==="Updated Successfully"){
        alert("Updated Successfully")
      }
      // this.basicInfoEdit = res
    })
  }

  basicInfo(userId){
    this.cardDetailService.basicCardDetail(userId).subscribe(res=>{
      this.basicInfoEdit = res
      this.formGroupUpdate = this.fb.group({
        cmobile: [res['cmobile'], [Validators.required, Validators.pattern("^[0-9]*$")]],
        cmobile1: [res['cmobile1'], [Validators.required, Validators.pattern("^[0-9]*$")]],
        cmobile2: [res['cmobile2'], [Validators.required, Validators.pattern("^[0-9]*$")]],
        cmobile3: [res['cmobile3'], [Validators.required, Validators.pattern("^[0-9]*$")]],
        cmobile4: [res['cmobile4'], [Validators.required, Validators.pattern("^[0-9]*$")]],
        cusername: [res['cusername'], [Validators.required]],
        cname: [res['cname'], [Validators.required]],
        cdesignation: [res['cdesignation'], [Validators.required]],
        cemail: [res['cemail'], [Validators.required]],
        cemail1: [res['cemail1'], [Validators.required]],
        cwebsite: [res['cwebsite'], [Validators.required]],
        cwebsite1: [res['cwebsite1'], [Validators.required]],
        caddress: [res['caddress'], [Validators.required]],
        caddress1: [res['caddress1'], [Validators.required]],
        caddress2: [res['caddress2'], [Validators.required]],
        cadddress3: [res['caddress3'], [Validators.required]],  
      })
    })
    console.log(this.formGroupUpdate)
  }
}
