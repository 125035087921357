import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { QuillModule } from 'ngx-quill';
import { NgxMaskModule } from 'ngx-mask';


@NgModule({
  imports: [CommonModule, QuillModule,NgxMaskModule.forRoot()]
  
})
export class CompanydetailModule {}
