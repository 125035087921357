import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup,Validators } from '@angular/forms';
import { CardDetailService } from '../card-detail.service';
class ImageSnippet {
  constructor(public src: string, public file: File) {
  }
}

@Component({
  selector: 'app-payment-details',
  templateUrl: './payment-details.component.html',
  styleUrls: ['./payment-details.component.css']
})
export class PaymentDetailsComponent implements OnInit {
  form: FormGroup;
  formGroupUpdatepayment: FormGroup;
  PaymentDetailsfoEdit:any;
  userId:any;
  data;
  constructor(private fb: FormBuilder,private cardDetailService:CardDetailService) {  }

  ngOnInit() {
    this.formGroupUpdatepayment = this.fb.group({
      paytm: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
      phonepa: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
      googlepa: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
      bhimupi: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
      otherupi: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
      qrcode: [''],
      accountnumber: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
      bankname: ['', [Validators.required]],
      branch: ['', [Validators.required]],
      bankholdername: ['', [Validators.required]],      
      ifsc: ['', [Validators.required]],
    })
    this.userId=localStorage.getItem("cardAdminToken")
    this.paymentDetails(this.userId)
  }

  update() {
    debugger
  if(!this.formGroupUpdatepayment.valid){
    return
  }
    
let temp = {
  ...this.formGroupUpdatepayment.value,
  'clid': this.PaymentDetailsfoEdit['clid'],
  'id': this.PaymentDetailsfoEdit['cid'],
  //'qrcode':this.data.src
}
this.cardDetailService.updatePaymentDetail(temp).subscribe(res=>{
      console.log(res["Message"])
      if(res["Message"]==="Updated Successfully"){
        alert("Updated Successfully")
      }
      // this.basicInfoEdit = res
    })
  }

  paymentDetails(userId){
    debugger;
    this.cardDetailService.PaymentDetail(userId).subscribe(res=>{
      this.PaymentDetailsfoEdit = res
      this.formGroupUpdatepayment = this.fb.group({
        paytm: [res['cpaytm'], [Validators.required, Validators.pattern("^[0-9]*$")]],
        phonepa: [res['cphonepa'], [Validators.required, Validators.pattern("^[0-9]*$")]],
        googlepa: [res['cgooglepa'], [Validators.required, Validators.pattern("^[0-9]*$")]],
        bhimupi: [res['cbhimupi'], [Validators.required, Validators.pattern("^[0-9]*$")]],
        otherupi: [res['cotherupi'], [Validators.required, Validators.pattern("^[0-9]*$")]],
        qrcode: [null],
        //bankaccount: [res['bankaccount']],  
        bankname: [res['cbankname']],  
        branch: [res['cbranch']],  
        bankholdername: [res['cbankholdername']],        
        ifsc: [res['cifsc']],  
        accountnumber: [res['caccountnumber'], [Validators.pattern("^[0-9]*$")]],      
      })
    })
    console.log(this.formGroupUpdatepayment)
  }

//   selectImage(event) {
//     debugger
//     if (event.target.files.length > 0) {
//         console.log(event.target.files)
//         const file: File = event.target.files[0];
//         const reader = new FileReader();
//         // tslint:disable-next-line:no-shadowed-variable
//         reader.addEventListener('load', (event: any) => {
//             this.data = new ImageSnippet(event.target.result, file);
//             this.PaymentDetailsfoEdit.cqrcode = this.data.src
//             console.log(this.data)
//         });
//         reader.readAsDataURL(file);
//     }
// }
}
