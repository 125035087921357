import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup,Validators } from '@angular/forms';
import { CardDetailService } from '../card-detail.service';

@Component({
  selector: 'app-servicendproducts',
  templateUrl: './servicendproducts.component.html',
  styleUrls: ['./servicendproducts.component.css']
})
export class ServicendproductsComponent implements OnInit {
  getproducts: any;
  formGroupUpdateproduct: FormGroup;
  userId: any;
  // servicename:any;
  // serviceamount:any;
  // servicedetail:any;
  constructor(private fb: FormBuilder,private cardService: CardDetailService) { }

  ngOnInit() {
    this.userId = localStorage.getItem("cardAdminToken")
    this.getProduct(this.userId)
    this.formGroupUpdateproduct = this.fb.group({
      productname: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
      amount: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],  
      desc: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],  
      serviceimage: ['', [Validators.required]],  
    })
  }

  getProduct(userId) {
    this.cardService.getProduct(userId).subscribe(res => {
      console.log(res)
      this.getproducts = res
    })
  }
  update(i, id) {
    console.log(i, id)
    debugger;
    let data =
    {
      "clid": this.getproducts[0]["clid"],
      "id": this.getproducts[0]["cid"],
   ...this.formGroupUpdateproduct.value
    }
    console.log(data)
    this.cardService.updateProductDetails(data).subscribe(res => {
      console.log(res["Message"])
      if (res["Message"] === "Updated Successfully") {
        alert("Updated Successfully")
        this.getProduct(this.userId)
      }
      // this.basicInfoEdit = res
    })
  }
  Addnew() {
    debugger;
    let data =
    {
      "clid": this.getproducts[0]["clid"],
      //"id": this.getproducts[0]["cid"],
   ...this.formGroupUpdateproduct.value
    }
    console.log(data)
    this.cardService.updateProductDetails(data).subscribe(res => {
      console.log(res["Message"])
      if (res["Message"] === "Add Successfully") {
        alert("Add Successfully")
        this.getProduct(this.userId)
      }
      // this.basicInfoEdit = res
    })
  }

  edit_details(product){
    debugger
    this.formGroupUpdateproduct = this.fb.group({
      productname: [product.cproductname, [Validators.required, Validators.pattern("^[0-9]*$")]],
      amount: [product.camount, [Validators.required, Validators.pattern("^[0-9]*$")]],  
      desc: [product.cdesc, [Validators.required, Validators.pattern("^[0-9]*$")]],  
      serviceimage: [null],  
    })
  }
} 

