import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ServiceService {

  constructor(private http:HttpClient) { }

  loginApi(data){
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers.append('Accept', 'application/json');
    console.log("logindetails",data)
    return this.http.post(`${environment.API_URL}/login`, data)

  }

  getData(){
    return this.http.get(`${environment.API_URL}/GetCustomer`)
  }

  

}
