import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CardDetailService } from '../card-detail.service';
import { ServiceService } from '../service.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  formLogin : FormGroup
  constructor(private router:Router, private service:ServiceService) {
    this.formLogin = new FormGroup({
      // [Validators.required, Validators.pattern(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/i)]
      "email": new FormControl('',[Validators.required]),
      "password": new FormControl('',[Validators.required, ])
    })
   }

  ngOnInit() {
    // this.get()
  }

  login(){
    let data = {
      mob:this.formLogin.value.email,
      pass:this.formLogin.value.password
    }
    console.log(data)
      this.service.loginApi(data).subscribe(res=>{
        // console.log("LOGINRETURNVALUE",res)
        // console.log("LOGINRETURNVALUE",res["id"])
        if(res["id"]){
          localStorage.setItem("cardAdminToken", res["id"])
          this.router.navigate(['/dashboard'])
        }else{
          alert(res["Message"])
        }
      })
    console.log(data)
    //   function makeid(length) {
    //     var result           = '';
    //     var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    //     var charactersLength = characters.length;
    //     for ( var i = 0; i < length; i++ ) {
    //        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    //     }
    //     return result;
    //  }
     
    //  console.log(makeid(50));
    //  localStorage.setItem("bhallaToken", makeid(50))
     console.log(localStorage.getItem("cardAdminToken"))
  }

  get(){
    this.service.getData().subscribe(res=>{
      console.log(res)
    })
  }

  
}
