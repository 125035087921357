import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-changepassword',
  templateUrl: './changepassword.component.html',
  styleUrls: ['./changepassword.component.css']
})
export class ChangepasswordComponent implements OnInit {

  changePwdForm: FormGroup;
  constructor() { 
    this.changePwdForm = new FormGroup({
      opass: new FormControl(''),
      new_password: new FormControl(''),
      confirm_pass: new FormControl('')
    })
  }

  ngOnInit() {
  }
  changePassword(){
    console.log(this.changePwdForm.value)
  }
}
