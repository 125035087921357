import { Component, OnInit } from '@angular/core';
import { FormControl, FormControlName, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-updateprofile',
  templateUrl: './updateprofile.component.html',
  styleUrls: ['./updateprofile.component.css']
})
export class UpdateprofileComponent implements OnInit {

  updateForm:FormGroup;
  constructor() { 
    this.updateForm = new FormGroup({
      name: new FormControl(''),
      email: new FormControl(''),
      phone: new FormControl(''),
      gstno: new FormControl('')

    })
  }

  ngOnInit() {
  }

  update(){
    console.log(this.updateForm.value)
  }
}
