import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { EditcardComponent } from './editcard/editcard.component';
import { FooterComponent } from './footer/footer.component';
import { LoginGuardGuard } from './login-guard.guard';
import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';
import { SociallinkComponent } from './sociallink/sociallink.component';



const routes: Routes = [
  { path: "", redirectTo: "login", pathMatch: "full" },
  { path: "", redirectTo: "dashboard", pathMatch: "full" },
  { path: "login", component: LoginComponent },
  { path: "signup", component: SignupComponent },
  { path: "dashboard", component: DashboardComponent, canActivate: [LoginGuardGuard] },
  { path: 'footer', component: FooterComponent },
  { path: 'editcard', component: EditcardComponent },
  { path: 'social', component: SociallinkComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
