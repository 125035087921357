import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-sidelist',
  templateUrl: './sidelist.component.html',
  styleUrls: ['./sidelist.component.css']
})
export class SidelistComponent implements OnInit {

  @Output() selection: EventEmitter<any> = new EventEmitter();
  selecttype: any = 'My Card';
  constructor() { }

  ngOnInit() {
  }
  selectType(type){
    this.selecttype = type
    this.selection.emit(type)
    
  }

}
