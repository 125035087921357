import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { QuillModule } from 'ngx-quill';
import { NgxMaskModule } from 'ngx-mask';
import{CompanydetailModule} from './companydetail/companydetail.module'



import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { FooterComponent } from './footer/footer.component';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { CarddetailComponent } from './carddetail/carddetail.component';
import { MyplanComponent } from './myplan/myplan.component';
import { UpdateprofileComponent } from './updateprofile/updateprofile.component';
import { ChangepasswordComponent } from './changepassword/changepassword.component';
import { MyleadsComponent } from './myleads/myleads.component';
import { UpgradethemeComponent } from './upgradetheme/upgradetheme.component';
import { EditcardComponent } from './editcard/editcard.component';
import { SidelistComponent } from './sidelist/sidelist.component';
import { BasicinfoComponent } from './basicinfo/basicinfo.component';
import { CompanydetailComponent } from './companydetail/companydetail.component';
import { ServicendproductsComponent } from './servicendproducts/servicendproducts.component';
import { SociallinkComponent } from './sociallink/sociallink.component';
import { VidondgaleryComponent } from './vidondgalery/vidondgalery.component';
import { PaymentDetailsComponent } from './payment-details/payment-details.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SignupComponent,
    DashboardComponent,
    FooterComponent,
    CarddetailComponent,
    MyplanComponent,
    UpdateprofileComponent,
    ChangepasswordComponent,
    MyleadsComponent,
    UpgradethemeComponent,
    EditcardComponent,
    SidelistComponent,
    BasicinfoComponent,
    CompanydetailComponent,
    ServicendproductsComponent,
    SociallinkComponent,
    VidondgaleryComponent,
    PaymentDetailsComponent,
    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatCardModule,
    MatIconModule,
    QuillModule.forRoot(),
    NgxMaskModule.forRoot(),
CompanydetailModule

  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
